import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"
import Container from "../components/Container/Container"
import Title from "../components/Title/Title"

import Hero from "../sections/hotel-policies/Hero/Hero"
import FancyNav from "../sections/common/FancyNav/FancyNav"
import Location from "../sections/common/Location/Location"

const PoliciesPage = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicHotelPolicy.data

  return (
    <>
      <SEO
        title={pageData.meta_title.text}
        description={pageData.meta_description.text}
      />
      <section>
        <Hero pageData={pageData} />
      </section>
      <section>
        <Container>
          <div className={`flex justify-center mx-auto prose`}>
            <div
              className="special-text-field"
              dangerouslySetInnerHTML={{ __html: pageData.page_content.html }}
            />
          </div>
        </Container>
      </section>
      <section className="pb-4 md:pb-6">
        <FancyNav />
      </section>
      <section className="pb-2 md:pb-3 lg:pb-5">
        <Location />
      </section>
    </>
  )
}

export default PoliciesPage

export const pageQuery = graphql`
  query {
    prismicHotelPolicy {
      data {
        page_pre_title {
          text
        }
        page_title {
          text
        }
        page_intro {
          html
        }
        page_content {
          html
        }

        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
