import React from "react"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"
import Button from "../../../components/Button/Button"

const Hero = ({ pageData }) => {
  return (
    <>
      <Container>
        <div className="flex flex-col items-center text-center">
          <div>
            <Title preTitle>{pageData.page_pre_title.text}</Title>
          </div>

          <div className="sm:mr-[-16px]">
            <Title singlePage>{pageData.page_title.text}</Title>
          </div>

          <div className="pt-3 sm:pt-7 max-w-[580px]">
            <div
              dangerouslySetInnerHTML={{ __html: pageData.page_intro.html }}
            />
          </div>
          <div className="pt-12">
            <Button arrowRight linkTo="/booking">
              BOOK NOW
            </Button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Hero
